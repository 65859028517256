import { Image } from '@graphcommerce/image'
import { Paper } from '@mui/material'
import step1 from '../../assets/images/order-process-step1.svg'
import step2 from '../../assets/images/order-process-step2.svg'
import step3 from '../../assets/images/order-process-step3.svg'
import step4 from '../../assets/images/order-process-step4.svg'
import { IconDescriptionGrid } from '../Home/WhyUseTempli'

export function OrderProcess() {
  const data: IconDescriptionGrid[] = [
    {
      icon: step1,
      details: '1. Choose item, upload art',
    },
    {
      icon: step2,
      details: '2. Submit order',
    },
    {
      icon: step3,
      details: '3. Receive unlimited proofs',
    },
    {
      icon: step4,
      details: '4. Order ships',
    },
  ]

  return (
    <Paper
      className='mb-5 flex w-full flex-col items-center justify-center bg-[#2196f312]'
      elevation={3}
      id='orderProcessPDP'
    >
      <span className='md:Type-XXXL-Medium Type-XL-Medium mt-5 flex w-[80%] items-center justify-center rounded-[10px] bg-none px-5 py-4 text-center italic md:italic'>
        How We Handle Your Custom Print Orders
      </span>
      <div className='mb-5 mt-8  grid w-[90%] grid-cols-2 justify-center justify-items-center gap-y-7 md:grid md:grid-cols-4 md:gap-y-0'>
        {data.map((item) => (
          <div className='flex flex-col items-center justify-start gap-4 md:gap-9'>
            <Image
              src={item.icon}
              layout='fixed'
              unoptimized
              height={150}
              width={150}
              className='rounded-[10px] bg-pure-white px-[15%] py-[15%] shadow-md'
            />
            <span className='Type-XL-Medium rounded-[10px] bg-pure-white px-3 py-2 text-center shadow-md'>
              {item.details}
            </span>
          </div>
        ))}
      </div>
    </Paper>
  )
}
