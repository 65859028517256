import { CustomerDocument, useCustomerQuery } from '@graphcommerce/magento-customer'
import { extendableComponent } from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import Head from 'next/head'
import { useEffect, useState } from 'react'
import { AlertPrompt } from '../Prompt/AlertPrompt'
import { QuestionAndAnswerItem } from './QuestionAndAnswerItem'

export type ProductQuestionsAndAnswersProps = {
  productId?: number | null | undefined
  productTitle?: string | null | undefined
  productUrl?: string | null | undefined
  qaData: any[] | null | undefined
  displayInTab?: boolean
  displayInHomePage?: boolean
}

export function ProductQuestionsAndAnswers(props: ProductQuestionsAndAnswersProps) {
  const {
    productId,
    productUrl,
    productTitle,
    qaData,
    displayInTab = true,
    displayInHomePage,
  } = props
  const [productQuestionsData, setProductQuestionsData] = useState<any[] | null | undefined>([]) // reviewData.response.bottomline
  const [questionFromUser, setQuestionFromUser] = useState<string>() // reviewData.response.bottomline

  const name = 'ImageText' as const
  const parts = ['root'] as const
  const { classes } = extendableComponent(name, parts)
  const [showQuestionInput, setShowQuestionInput] = useState<boolean>(true)
  const [isDisplayAlertPrompt, setisDisplayAlertPrompt] = useState<boolean>(false)

  const [toggleLabel, setToggleLabel] = useState<string>(i18n._('Hide form'))
  const [userName, setUserName] = useState<string>()

  // const [uploadQuestion] = useMutation(AttachArtworkDocument)

  const resetValues = () => {
    setisDisplayAlertPrompt(false)
    setQuestionFromUser(undefined)
    setisDisplayAlertPrompt(false)
    setToggleLabel('Hide form')
    setShowQuestionInput(true)
  }

  const closeOnConfirm = () => {
    resetValues()
  }

  const dashboard = useCustomerQuery(CustomerDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const customer = dashboard.data?.customer

  const questionAndAnswerStructuredData = productQuestionsData?.map((qAndA) => ({
    '@type': 'Question',
    name: qAndA.title,
    acceptedAnswer: {
      '@type': 'Answer',
      text: `<p>${qAndA.short_answer}</p>`,
    },
  }))

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questionAndAnswerStructuredData,
  }

  useEffect(() => {
    if (qaData && qaData.length > 0) {
      setProductQuestionsData(qaData)
    } else {
      setProductQuestionsData([])
    }
  }, [qaData])

  return (
    <div className={`${classes.root} ${displayInTab ? 'mb-[200px]' : ''}`}>
      {questionAndAnswerStructuredData && questionAndAnswerStructuredData?.length > 0 && (
        <Head>
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
          />
        </Head>
      )}
      {(!productQuestionsData || productQuestionsData?.length < 1) && !displayInHomePage && (
        <div className='Type-XXXL-Regular mx-[20px] my-[80px] flex flex-col items-start justify-center'>
          <span className='md:Type-XL-Regular Type-Large-Regular'>
            There are no questions specific to this product or category yet. You may visit{' '}
            <a href='https://templi.gorgias.help/' className='text-teal underline'>
              Templi's FAQ page
            </a>{' '}
            for answers to more general questions.
          </span>
        </div>
      )}
      {productQuestionsData && productQuestionsData?.length > 0 && (
        <div
          className='
            flex w-full items-center justify-center'
        >
          <div
            className={`mx-5 w-full  ${
              displayInHomePage
                ? 'flex flex-col gap-x-10 md:mx-10 lg:mx-[40px] lg:grid lg:grid-cols-2'
                : 'flex flex-col md:mx-[5%] lg:mx-[15%]'
            }`}
          >
            {productQuestionsData
              ?.filter((item) => item.status === 1)
              .map((question) => (
                <QuestionAndAnswerItem {...question} customer={customer} />
              ))}
          </div>
        </div>
      )}
      <AlertPrompt
        title={i18n._('Thank you')}
        isDisplayed={isDisplayAlertPrompt}
        setIsDisplayed={setisDisplayAlertPrompt}
        message={i18n._('Thank you for submitting a question!')}
        callback={closeOnConfirm}
      />
    </div>
  )
}
